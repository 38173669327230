import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";
import { useTranslation } from "react-i18next";

//Here you can add Cards and their respective info
function Cards() {
	const { t } = useTranslation();

	return (
		<div className="cards">
			<h1 id="gettingStarted">{t("Card-H1")}</h1>
			<p>{t("Card-P")}</p>
			<div className="cards__container">
				<div className="cards__wrapper">
					<ul className="cards__item">
						<CardItem
							src="../images/img-9.jpg"
							text={t("Card-1")}
							label={t("Card-label-1")}
							path="/money"
							alt="money"
						/>
						<CardItem
							src="../images/img-2.jpg"
							text={t("Card-2")}
							label={t("Card-label-2")}
							path="/bitcoin"
						/>
						<CardItem
							src="../images/img-3.jpg"
							text={t("Card-3")}
							label={t("Card-label-3")}
							path="/blockchain"
						/>
					</ul>
					<ul className="cards__item">
						<CardItem
							src="../images/img-1.jpg"
							text={t("Card-4")}
							label={t("Card-label-4")}
							path="/fiat"
						/>
						<CardItem
							src="../images/img-4.jpg"
							text={t("Card-5")}
							label={t("Card-label-5")}
							path="/white-paper"
						/>
					</ul>
					<ul className="cards__item">
						<CardItem
							src="/images/img-2.jpg"
							text={t("Card-6")}
							label="The Bitcoin Test"
							path="/quiz"
						/>
					</ul>
				</div>
			</div>
			{/* <Footer /> */}
		</div>
	);
}

export default Cards;
