import React from "react";
import "./Button.css";
import { Link } from "react-router-dom";

//applies either button style primary or outline. If it doesn have any of the classes applied to it, then it will automatically be given button primary by default

const STYLES = ["btn--primary", " btn--outline"];

const SIZES = ["btn--medium", "btn--large"];
export const Button = ({
	children,
	type,
	onClick,
	buttonStyle,
	buttonSize,
	path,
}) => {
	const checkButtonStyle = STYLES.includes(buttonStyle)
		? buttonStyle
		: STYLES[0];

	const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
	//here below, whatever you put in the button, it will be displayed. <button> XYZ </button XYZ is the children of button... basic
	return (
		<Link to={path} className="btn-mobile">
			<button
				className={`btn ${checkButtonStyle} ${checkButtonSize}`}
				onClick={onClick}
				type={type}
			>
				{children}
			</button>
		</Link>
	);
};
