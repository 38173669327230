import React from "react";
import "../../App.css";
import { useTranslation } from "react-i18next";
import "./Blockchain.css";

function Blockchain() {
	const { t } = useTranslation();
	return (
		<div className="resources-container">
			<h1 className="resource">{t("Bitcoin-temp-message")}</h1>
		</div>
	);
}
export default Blockchain;
