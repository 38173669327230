import React from "react";
import "../../App.css";
import "./Money.css";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";

export default function Money() {
	const { t } = useTranslation();

	return (
		<div className="money-container">
			<h1 className="money">{t("Money-title1")}</h1>
			<div className="main-content-container">
				<h3 className="sub-title">{t("Money-subtitle1")}</h3>
				<p className="main-content">{t("Money-Content2")}</p>
				<h3 className="sub-title">{t("Money-subtitle2")}</h3>
				<p className="main-content">{t("Money-Content1")}</p>
				<p className="main-content">{t("Money-Content3")}</p>
				<h3 className="sub-title">{t("Money-subtitle3")}</h3>
				<p className="main-content">{t("Money-Content4")}</p>
				<p className="main-content">{t("Money-Content5")}</p>
				<p className="main-content">{t("Money-Content6")}</p>
				<p className="main-content">{t("Money-Content7")}</p>
				<p className="main-content">{t("Money-Content8")}</p>
			</div>
			<Footer></Footer>
		</div>
	);
}
// The best way to learn about bitcoin is to first understand what
// 				money is. This sounds trivial but it is a very important concept
// 				when we want to dive deep into subjects like bitcoin.
/*
<div className="definition-container">
<h2>{t("Money-title2")}</h2>
<p className="definition">{t("Money-definition1")}</p>

<h2>{t("Money-title3")}</h2>
<p className="definition">{t("Money-definition2")}</p>

<h2>{t("Money-title4")}</h2>
<p className="definition">{t("Money-definition3")}</p>
</div>
*/
