import React from "react";
import "../../App.css";
import Footer from "../Footer";
import HeroSection from "../HeroSection";

function Home() {
	return (
		<>
			<HeroSection />
			{/* <Cards /> */}
			<Footer />
		</>
	);
}
export default Home;
//have the Home button selected when you are at home
