import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "./Navbar.css";

function Navbar() {
	//creating a state and a function to handle the clicking of the hamburger font
	//whatever the value click has, change it to !click
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	//changes hamburger font when you click on items within it
	const closeMobileMenu = () => setClick(false);

	// eslint-disable-next-line
	const [button, setButton] = useState(true);

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		} else {
			setButton(true);
		}
	};

	const changeLanguage = (ln) => {
		return () => {
			i18n.changeLanguage(ln);
			console.log(`Language changed to ${ln}`);
		};
	};

	useEffect(() => {
		showButton();
	}, []);

	window.addEventListener("resize", showButton);
	const { t, i18n } = useTranslation();
	return (
		<>
			<nav className="navbar">
				<div className="navbar-container">
					<Link
						to="/"
						className="navbar-logo"
						onClick={closeMobileMenu}
					>
						<div className="logo-text">21BTC</div>{" "}
						<div className="navbar-mobile-rocket">
							<i class="fas fa-rocket"></i>
						</div>
					</Link>

					<div className="menu-icon" onClick={handleClick}>
						<i className={click ? "fas fa-times" : "fas fa-bars"} />
					</div>

					<ul className={click ? "nav-menu active" : "nav-menu"}>
						<li className="nav-item">
							<Link
								to="/"
								className="nav-links"
								onClick={closeMobileMenu}
							>
								{t("nav1")}
							</Link>
						</li>
						<li className="nav-item">
							<Link
								to="/getting-started"
								id="gettingStarted"
								className="nav-links"
								onClick={closeMobileMenu}
							>
								{t("nav2")}
							</Link>
						</li>

						<li className="nav-item">
							<Link
								to="/resources"
								className="nav-links"
								onClick={closeMobileMenu}
							>
								{t("nav3")}
							</Link>
						</li>

						<li className="nav-item">
							<Link
								to="/white-paper"
								className="nav-links"
								onClick={closeMobileMenu}
							>
								{t("nav4")}
							</Link>
						</li>
					</ul>
					<div>
						<button
							className="translate-button"
							onClick={changeLanguage("is")}
						>
							Íslenska
						</button>
						<button
							className="translate-button"
							onClick={changeLanguage("en")}
						>
							English
						</button>
					</div>
					{/* {button && (
						<Button path="sign-up" buttonStyle="btn--outline">
							SIGN UP
						</Button>
					)} */}
				</div>
			</nav>
		</>
	);
}

export default Navbar;
