import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translationEN.json";
import translationIS from "./locales/is/translationIS.json";

// the translations
const resources = {
	en: {
		translation: translationEN,
	},
	is: {
		translation: translationIS,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: "is",

	keySeparator: false, // we do not use keys in form messages.welcome

	interpolation: {
		escapeValue: false, // react already saves from xss
	},
});

export default i18n;
