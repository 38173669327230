import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import { useTranslation } from "react-i18next";

function HeroSection() {
	const { t } = useTranslation();

	return (
		<div className="hero-container">
			{/* <video src="/videos/video-1.mp4" autoPlay loop muted /> */}
			<h2>
				{t("greeting")}
				<i class="fas fa-rocket"></i>
			</h2>
			<h4>{t("greeting2")}</h4>
			<div className="hero-btns">
				<Button
					className="btns"
					buttonStyle="btn--outline"
					buttonSize="btn--large"
					path="/getting-started"
				>
					{t("button1")}
				</Button>

				{/* <Button
					className="btns"
					buttonStyle="btn--primary"
					buttonSize="btn--large"
					path="/getting-started"
				>
					{t("button2")} <i className="far fa-play-circle" />
				</Button> */}
			</div>
		</div>
	);
}

export default HeroSection;
