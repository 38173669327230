import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "../../App.css";

function WhitePaper() {
	const [numPages, setNumPages] = useState(null);
	// eslint-disable-next-line
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}
	// eslint-disable-next-line
	function changePage(offSet) {
		setPageNumber((prevPageNumber) => prevPageNumber + offSet);
	}

	// function changePageBack() {
	// 	changePage(-1);
	// }

	// function changePageNext() {
	// 	changePage(+1);
	// }

	return (
		<div className="paper-container">
			{/* <Document file="/bitcoin.pdf" onLoadSuccess={onDocumentLoadSuccess}>
				<Page height="1000" pageNumber={pageNumber}></Page>
			</Document>
			<p>
				{" "}
				Page {pageNumber} of {numPages}
			</p>
			{pageNumber > 1 && (
				<button onClick={changePageBack}>Previous Page</button>
			)}
			{pageNumber < numPages && (
				<button onClick={changePageNext}>Next Page</button>
			)} */}

			<center>
				<div>
					<Document
						file="bitcoin.pdf"
						onLoadSuccess={onDocumentLoadSuccess}
					>
						{Array.from(new Array(numPages), (el, index) => (
							<Page
								height="1500"
								key={`page_${index + 1}]`}
								pageNumber={index + 1}
							/>
						))}
					</Document>
				</div>
			</center>
		</div>
	);
}
export default WhitePaper;

//Here there are two ways to display the pdf document.
// The first way is to have one page displayed at a time and having buttons to navigate with changePageBack and changePageNext functions
// The Second way is to simply display the whole pdf document and letting the user scroll down.
