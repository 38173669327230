import React, { Suspense } from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import WhitePaper from "./components/pages/WhitePaper";
import SignUp from "./components/pages/SignUp";

import "./App.css";
import Cards from "./components/Cards";
import Money from "./components/pages/Money";
import Resources from "./components/pages/Resources";
import Bitcoin from "./components/pages/Bitcoin";
import Blockchain from "./components/pages/Blockchain";
import Fiat from "./components/pages/Fiat";
import Quiz from "./components/pages/Quiz";

//Here we can add the Routes

function App() {
	return (
		<>
			<Suspense fallback={null}></Suspense>
			<Router>
				<Navbar />
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/white-paper" component={WhitePaper} />
					<Route path="/getting-started" component={Cards} />
					<Route path="/sign-up" component={SignUp} />
					<Route path="/resources" component={Resources} />
					<Route path="/money" component={Money} />
					<Route path="/bitcoin" component={Bitcoin} />
					<Route path="/blockchain" component={Blockchain} />
					<Route path="/fiat" component={Fiat} />
					<Route path="/quiz" component={Quiz} />
				</Switch>
			</Router>
		</>
	);
}

export default App;
